* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: none;
}

select:focus {
  outline: none
}

input {
  outline: none;
}

li {
  list-style-type: none;
}

.App {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to right, #F34680, #4480F8);

  .mainContent {
    width: 100%;
    height: auto;
    padding-top: 125px;
  }
}
