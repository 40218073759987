.headers {
  height: 125px;
  width: 100%;
  background: transparent;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.4rem;
  position: fixed;
  top: 0;
  left: 0;

  .headers-leftcontent {
    width: 239px;
    height: 124px;
    position: relative;

    .logo {
      display: block;
      width: 100%;
      height: 124px;
    }

    .versionB {
      position: absolute;
      right: -40px;
      bottom: 55px;
      font-size: 18px;
      font-family: Roboto-BlackItalic, Roboto;
      color: #fff;
      line-height: 21px;
    }

    .titleName {

      animation:
        typing 3s steps(20, end) forwards infinite;

      width: 0;
    }

    .comName {
      position: absolute;
      left: 113px;
      top: 28px;
      height: 21px;
      font-size: 16px;
      color: #fff;
      line-height: 21px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
    }

    @keyframes typing {
      0% {
        width: 0;
        opacity: 1;
      }

      66% {
        width: 150px;
        opacity: 1;
      }

      100% {
        width: 150px;
        opacity: 0;
      }
    }
  }

  // }
}
