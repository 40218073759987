.indexPage {
  width: 100%;
  min-height: calc(100vh - 245px);
  display: flex;
  justify-content: center;
  align-items: center;

  .leftInfo {
    width: 80%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .nextDate {
    // margin-left: 4.3rem;
    width: auto;

    .dateName {
      height: auto;
      display: flex;
      align-items: center;
      color: #fff;

      .num {
        width: 2rem;
        height: 1.5rem;
        border-radius: 8px;
        border: 1px solid #979797;
        margin-right: .33rem;
        font-size: 1rem;
        font-family: Roboto-BlackItalic, Roboto;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        font-size: .4rem;
        font-family: Roboto-BlackItalic, Roboto;
        color: #FFFFFF;
      }
    }

    .desc {
      margin-top: .33rem;
      width: 2rem;
      font-size: .24rem;
      // font-family: Roboto-BlackItalic, Roboto;
      font-family: 'Roboto';
      font-style: italic;
      color: #FFFFFF;
      line-height: .28rem;
      text-align: center;
    }
  }
}
